<div class="flex items-center sm-w-100">
    <div [formGroup]="citGroup" class="grid grid-cols-3  sm-grid-cols-1 grill sm-w-100">
        <mat-form-field color="accent" class="formfield">
            <mat-label>CIT</mat-label>
            <mat-select (selectionChange)="setValue($event.value)" formControlName="cit" required>
                <mat-option *ngFor="let cit of cits" [value]="cit">
                    {{cit.firstName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="citGroup.controls.cit.errors?.required">Please select CIT</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield mx-12">
            <input matInput type="text" placeholder="CIT Email" [(ngModel)]="email"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield">
            <input matInput type="text" placeholder="CIT Number" [(ngModel)]="phNo"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
    </div>
    <mat-icon (click)="showUser()"><span class="material-symbols-outlined"> av_timerss </span></mat-icon>
</div>