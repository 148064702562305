import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BookAllotmentService {
  constructor(private http: HttpClient, private dialog: MatDialog) {}

  allotBookToUser(body: {
    role: string;
    userId: string;
    projectId: string;
    books: any;
  }): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/allotment/allotBookToUser`,
      body
    );
  }

  getAllotedBookToUser(_id: string): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/allotment/getDataByUser`,
      {
        userId: _id,
      }
    );
  }

  getAllotedBookByProjectAndRole(body: {
    projectId: String;
    role: String;
    userId: String;
  }): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/allotment/getDataByProjectAndRole`,
      body
    );
  }
}
