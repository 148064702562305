<div class="flex items-center sm-w-100">
  <div [formGroup]="coOrdinatorGroup" class="grid grid-cols-3  sm-grid-cols-1 grill sm-w-100">
    <mat-form-field color="accent" class="formfield">
      <mat-label>Coordinator</mat-label>
      <mat-select (selectionChange)="setValue($event.value)" formControlName="coordinator" required>
        <mat-option *ngFor="let coordinator of coordinators" [value]="coordinator">
          {{ coordinator.firstName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="coOrdinatorGroup.controls.coordinator.errors?.required">Please select coordinator</mat-error>
    </mat-form-field>
    <mat-form-field color="accent" class="formfield mx-12">
      <input matInput type="text" placeholder="Coordinator Email" [(ngModel)]="email"
        [ngModelOptions]="{ standalone: true }" disabled />
    </mat-form-field>
    <mat-form-field color="accent" class="formfield">
      <input matInput type="text" placeholder="Coordinator Number" [(ngModel)]="phNo"
        [ngModelOptions]="{ standalone: true }" disabled />
    </mat-form-field>
  </div>
  <mat-icon (click)="showUser()"><span class="material-symbols-outlined"> av_timerss </span></mat-icon>

</div>