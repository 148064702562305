<div class="flex items-center sm-w-100">
    <div [formGroup]="facilitatorGroup" class="grid grid-cols-3 sm-grid-cols-1 sm-w-100">
        <mat-form-field color="accent" class="formfield">
            <mat-label>Facilitator</mat-label>
            <mat-select (selectionChange)="setValue($event.value)" formControlName="facilitator" required>
                <mat-option *ngFor="let facilitator of facilitators" [value]="facilitator">
                    {{facilitator.firstName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="facilitatorGroup.controls.facilitator.errors?.required">Please select facilitator
            </mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield mx-12">
            <input matInput type="text" placeholder="Facilitator Email" [(ngModel)]="email"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield">
            <input matInput type="text" placeholder="Facilitator Number" [(ngModel)]="phNo"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
    </div>
    <mat-icon (click)="showUser()"><span class="material-symbols-outlined"> av_timerss </span></mat-icon>
</div>