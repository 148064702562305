import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AdminLoginService } from './services/login/adminlogin.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'rhema-app';
  adminLoggedIn: any;
  adminLoggedData: any;
  isLoggedIn: boolean = false;
  currentRoute: any;
  currentRole: any;
  isUserLoggedInCheck: boolean | any;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  currentYear: any;
  checkAdmin: any;

  constructor(
    private router: Router,
    private adminLoginService: AdminLoginService
  ) {}

  ngOnInit() {

    this.isUserLoggedInCheck = localStorage.getItem('isUserLoggedIn');
    this.currentYear = new Date().getFullYear();
    this.adminLoginService.listen().subscribe((result) => {
      this.adminLoggedIn = result.loggedIn;
      this.currentRole = result.role;
    });
    this.checkAdmin = localStorage.getItem('checkAdmin');
    this.adminLoggedIn = JSON.parse(this.checkAdmin);
    this.currentRole = localStorage.getItem('role');

    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     this.currentRoute = event.url;
    //     if (this.adminLoggedIn && this.currentRoute === '/' && this.currentRole !== 'Coordinator') {
    //       this.router.navigateByUrl('/');
    //     } else if (this.adminLoggedIn && this.currentRoute === '/' && this.currentRole === 'Coordinator') {
    //       this.router.navigateByUrl('/dashboard');
    //     } else if (this.adminLoggedIn === false && this.currentRoute === '/reset-password') {
    //       this.router.navigateByUrl('/');
    //     }
    //   }
    // });

  }

  logOut() {
    const userLoggedInData = {
      loggedIn: false,
      role: 'SuperAdmin',
    };
    this.adminLoginService.isLoggedIn = userLoggedInData;
    this.adminLoggedData = this.adminLoginService.adminIsLoggedIn;
    this.adminLoggedIn = this.adminLoggedData.loggedIn;
    this.currentRoute = this.adminLoggedData.role;

    localStorage.setItem('checkAdmin', this.adminLoggedIn.toString());
    localStorage.setItem('role', this.currentRoute);
    localStorage.setItem('isUserLoggedIn', 'false');
    this.adminLoginService.emitToSubscribers();

    this.isUserLoggedInCheck = localStorage.getItem('isUserLoggedIn');
    this.router.navigateByUrl('/login');
  }

  redirectToHome() {
    this.router.navigateByUrl('/');
  }

  // onActivate(event: any) {
  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth'
  //   });
  // }

  onActivate(e: any, scrollContainer: any) {
    scrollContainer.scrollTop = 0;
  }
}
