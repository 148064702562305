import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';

@Component({
  selector: 'app-mt-translator',
  templateUrl: './mt-translator.component.html',
  styleUrls: ['./mt-translator.component.scss'],
})
export class MtTranslatorComponent implements OnInit {
  @Input('mttGroup')
  public mttGroup: FormGroup | any;
  @Input() changing: Subject<boolean> | any;
  @Input() projectId: any | '';
  @Output()
  email: string | any;
  phNo: string | any;
  transalators: any;
  @Input() setProjectRole: any;
  timeLineInfo: any;
  constructor(private projectProfileService: ProjectProfileService) {}

  ngOnInit(): void {
    this.changing.subscribe((response: any) => {
      console.log("Response -- ",response)
      this.transalators = response;
      this.email = '';
      this.phNo = '';

      if (this.setProjectRole) {
        const index = this.transalators.findIndex(
          (data: any) => data._id === this.setProjectRole._id
        );
        this.mttGroup.controls.mtt.setValue(this.transalators[index]);
        this.setValue(this.transalators[index]);
        this.mttGroup.controls.mtt.disable();
      }
    });
  }

  setValue(object: any) {
    this.timeLineInfo = object;
    this.email = object.email;
    this.phNo = object.mobileNumber;
  }

  showUser() {
    this.projectProfileService.showTimeline(
      this.timeLineInfo.id,
      this.projectId,
      this.timeLineInfo.role
    );
  }

  showBookAllotment() {
    this.projectProfileService.showBookAllotmentTable(
      this.timeLineInfo.id,
      this.projectId,
      this.timeLineInfo.role
    );
  }
}
