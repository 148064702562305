<div class="flex items-center sm-w-100">
  <div
    [formGroup]="mttGroup"
    class="grid grid-cols-3 grill sm-grid-cols-1 sm-w-100"
  >
    <mat-form-field color="accent">
      <mat-label>MTT</mat-label>
      <mat-select
        (selectionChange)="setValue($event.value)"
        formControlName="mtt"
        required
      >
        <mat-option
          *ngFor="let transalator of transalators"
          [value]="transalator"
        >
          {{ transalator.firstName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="mttGroup.controls.mtt.errors?.required"
        >Please select MTT</mat-error
      >
    </mat-form-field>
    <mat-form-field color="accent" class="formfield mx-12">
      <input
        matInput
        type="text"
        placeholder="MTT Email"
        [(ngModel)]="email"
        [ngModelOptions]="{ standalone: true }"
        disabled
      />
    </mat-form-field>
    <mat-form-field color="accent" class="formfield">
      <input
        matInput
        type="text"
        placeholder="MTT Number"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="phNo"
        disabled
      />
    </mat-form-field>
  </div>
  <mat-icon (click)="showUser()"
    ><span class="material-symbols-outlined"> av_timerss </span></mat-icon
  >
  <mat-icon (click)="showBookAllotment()"
    ><span class="material-symbols-outlined"> assignment_ind </span></mat-icon
  >
</div>
