import {
  Component,
  Inject,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  Chapter,
  TimeLineDetails,
  TimeLine,
} from 'src/app/page/project-edit/project-edit.component';
@Component({
  selector: 'app-sub-table',
  templateUrl: './sub-table.component.html',
  styleUrls: ['./sub-table.component.scss'],
})
export class SubTableComponent implements OnInit {
  @Input() book: TimeLineDetails | undefined;
  @Input() stageName: string | undefined;
  @Input('noOfDays')
  @Output()
  newItemEvent = new EventEmitter<any>();

  isDisplay: boolean = false;

  ngOnInit(): void {                    
  }
  constructor() {}

  showChapters() {
    this.isDisplay = !this.isDisplay;
  }

  // timeLineChange(event: any) {
  //   if (this.book?.bookName === event.target.name) {
  //     this.book!.bookName = event.target.name;
  //     this.book!.noOfDays = event.target.value;
  //   } else {
  //     const particularChapter: Chapter = this.book?.chapters.find(
  //       (chapter: Chapter) => chapter.chapterName === event.target.name
  //     ) as Chapter;
  //     particularChapter.noOfDays = event.target.value;
  //   }
  //   console.log("book event",this.book);
  //   this.newItemEvent.emit(this.book);
  // }

  timeLineChange(event: any) {
    if (this.book?.bookName === event.target.name) {
      this.book!.bookName = event.target.name;
      this.book!.noOfDays = event.target.value;
    } else {
      const particularChapter: Chapter = this.book?.chapters.find(
        (chapter: Chapter) => chapter.chapterName === event.target.name
      ) as Chapter;
      particularChapter.noOfDays = event.target.value;
    }
    this.newItemEvent.emit(this.book);
  }

}
