import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-technician',
  templateUrl: './technician.component.html',
  styleUrls: ['./technician.component.scss'],
})
export class TechnicianComponent implements OnInit {
  @Input('technicianGroup')
  public technicianGroup: FormGroup | any;
  technicians: any;
  email: string | any;
  phNo: string | any;
  @Input() setProjectRole: any;
  @Input() projectId: any | '';
  timeLineInfo: any;


  constructor(private userDataService: UserDataService,
    private projectProfileService: ProjectProfileService,
    ) {}

  ngOnInit(): void {
    this.getUserByRole('Technician');
  }

  getUserByRole(role: string) {
    this.userDataService.getUserByRole(role).subscribe((userData: any) => {
      this.technicians = this.alphaOrder(userData, 'firstName');
      this.email = '';
      this.phNo = '';
      if (this.setProjectRole) {
        const index = this.technicians.findIndex(
          (data: any) => data._id === this.setProjectRole._id
        );

        this.technicianGroup.controls.technician.setValue(
          this.technicians[index]
        );
        this.setValue(this.technicians[index]);
        this.technicianGroup.controls.technician.disable();
      }
    });
  }

  setValue(object: any) {
    this.timeLineInfo=object;
    this.email = object.email;
    this.phNo = object.mobileNumber;
  }

  alphaOrder(list: any, fieldName: any) {
    return list.sort((a: any, b: any) => {
      if (a[fieldName] > b[fieldName]) return 1;
      else if (a[fieldName] < b[fieldName]) return -1;
      else return 0;
    });
  }

  showUser() {
    this.projectProfileService.showTimeline(this.timeLineInfo.id,this.projectId,this.timeLineInfo.role);
  }
}
