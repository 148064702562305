import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() message: string = '' 

  @Output() newItemEvent = new EventEmitter<string>();

  onCloseNotification() {
    this.newItemEvent.emit('false')
  }
  constructor() { }

  ngOnInit(): void {
  }

}



