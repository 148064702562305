import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  TimeLineDetails,
  TimeLine,
} from 'src/app/page/project-edit/project-edit.component';

/**
 * @title Table with pagination
 */
@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements AfterViewInit, OnInit {
  dataSource: MatTableDataSource<TimeLineDetails>;
  stage: any = [];
  defaultStage: any;
  indexValue: any;
  roleStageName = [
    { role: 'MTT', stageName: ['Draft', 'Cross Check', 'Master Record'] },
    { role: 'Facilitator', stageName: ['Community Check','Back Translation'] },
    { role: 'CIT', stageName: ['CIT Check'] },
    { role: 'Consultant', stageName: ['Consultant Check'] },
    { role: 'Technician', stageName: ['Publishing'] },
    {
      role: 'Coordinator',
      stageName: ['Stage 1'],
    },
    {
      role: 'Translation Manager',
      stageName: ['Stage 1'],
    },
  ];
  displayedColumns: string[] = ['bookName', 'noOfDays'];
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  stageValue: any | undefined;
  ngOnInit() {}

  constructor(
    public dialogRef: MatDialogRef<TimelineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TimeLine
  ) {
    this.stage = this.roleStageName.filter(
      (stageFilter: any) => stageFilter.role == this.data.role
    );
    this.defaultStage=this.stage[0].stageName[0];
    let ffff: any = this.data.timeLineDetails[0];
    this.dataSource = new MatTableDataSource<TimeLineDetails>(ffff);
  }

  async stageField(event: any) {    
    for (let i = 0; i < this.data.timeLineDetails.length; i++) {
      Object.values(this.data.timeLineDetails[i]).forEach((e) => {
        if (e.stageName == event) {
          this.stageValue = e.stageName;
          this.indexValue = i;
          return;
        }
      });
    }
    let ds: any = this.data.timeLineDetails[this.indexValue];
    this.dataSource = new MatTableDataSource<TimeLineDetails>(ds);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  closeModal() {
    this.dialogRef.close({
      projectId: this.data.projectId,
      userId: this.data.userId,
      role: this.data.role,
      userArr: this.timeLineDetails,
    });
  }
  timeLineDetails: TimeLineDetails[] = this.data.timeLineDetails;
  // timeLineDetails: TimeLineDetails[] = [];

  // async addItem(value: TimeLineDetails) {
  //   if (this.timeLineDetails.length == 0) {
  //     this.timeLineDetails[0] = value;
  //   } else if (this.timeLineDetails.length) {
  //     let timeLine = this.timeLineDetails.findIndex(
  //       (timeLine: TimeLineDetails) => timeLine.bookName === value.bookName
  //     );
  //     if (timeLine > -1) {
  //       this.timeLineDetails[timeLine] = value;
  //     } else {
  //       this.timeLineDetails = [...this.timeLineDetails, value];
  //     }
  //   }
  //   console.log("timeLineDetails",this.timeLineDetails);

  // }

  async addItem(value: any) {
    for (let x = 0; x < this.timeLineDetails.length; x++) {
      Object.values(this.data.timeLineDetails[x]).forEach((e) => {
        if (e.bookName === value.bookName && e.stageName === value.stageName) {
          e.bookName = value.bookName;
          e.chapters = value.chapters;
          e.noOfDays = value.noOfDays;
          e.stageName = value.stageName;
        }
      });
    }
    // let timeLine = this.timeLineDetails.findIndex(
    //   (timeLine: TimeLineDetails) => timeLine.bookName === value.bookName
    // );
    // this.timeLineDetails[timeLine] = value;

    // if (this.timeLineDetails.length == 0) {
    //   this.timeLineDetails[0] = value;
    // } else if (this.timeLineDetails.length) {
    //   let timeLine = this.timeLineDetails.findIndex(
    //     (timeLine: TimeLineDetails) => timeLine.bookName === value.bookName
    //   );
    //   if (timeLine > -1) {
    //     this.timeLineDetails[timeLine] = value;
    //   } else {
    //     this.timeLineDetails = [...this.timeLineDetails, value];
    //   }
    // }
  }
}
