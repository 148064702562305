import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router'; 
import { HomeComponent } from './page/home/home.component';
import { LoginComponent } from './page/login/login.component'; 

const routes: Routes = [
  { path: "", component: HomeComponent, pathMatch: 'full' },
  { path: "login", component: LoginComponent, pathMatch: 'full' },
  { path: '', loadChildren: () => import('./user/user.module').then(m => m.UserModule) },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
