import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  TimeLine,
  TimeLineDetails,
} from 'src/app/page/project-edit/project-edit.component';
import { BookAllotmentService } from 'src/app/services/bookAllotment/book-allotment.service';
import { DialogsService } from 'src/app/services/dialog/dialog.service';
import { PopUpModalComponent } from '../pop-up-modal/pop-up-modal.component';
import { TimelineComponent } from '../timeline/timeline.component';

interface BookAllotmentInterface {
  isChecked: boolean;
  bookName: any;
  chapterName: any;
}

@Component({
  selector: 'app-book-allotment',
  templateUrl: './book-allotment.component.html',
  styleUrls: ['./book-allotment.component.scss'],
})
export class BookAllotmentComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<TimeLineDetails> | any;
  stage: any = [];
  role: string | any;
  projectId: string | any;
  userId: string | any;
  defaultStage: any;
  indexValue: any;
  isUpdated: boolean = false;
  roleStageName = [
    { role: 'MTT', stageName: ['Draft', 'Cross Check', 'Master Record'] },
    { role: 'Facilitator', stageName: ['Community Check', 'Back Translation'] },
    { role: 'CIT', stageName: ['CIT Check'] },
    { role: 'Consultant', stageName: ['Consultant Check'] },
    { role: 'Technician', stageName: ['Publishing'] },
    {
      role: 'Coordinator',
      stageName: ['Stage 1'],
    },
    {
      role: 'Translation Manager',
      stageName: ['Stage 1'],
    },
  ];
  displayedColumns: string[] = ['bookName', 'noOfDays'];
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  stageValue: any | undefined;
  otherAllocationBooks: any;

  book_allotment: any = {
    projectId: '',
    userId: '',
    role: '',
    books: [],
  };

  constructor(
    private dialogService: DialogsService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TimelineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TimeLine,
    private bookAllotmentService: BookAllotmentService
  ) {
    this.stage = this.roleStageName.filter(
      (stageFilter: any) => stageFilter.role == this.data.role
    );
    this.defaultStage = this.stage[0].stageName[0];
    let ffff: any = this.data.timeLineDetails[0];
    this.dataSource = new MatTableDataSource<TimeLineDetails>(ffff);
    this.projectId = this.data.projectId;
    this.role = this.data.role;
    this.userId = this.data.userId;
    this.loadBookAllotmentData();
    this.loadOtherBookAllocation();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.dialogService.closeModal();
  }

  async loadBookAllotmentData() {
    try {
      this.bookAllotmentService
        .getAllotedBookToUser(this.userId)
        .subscribe(async (res) => {
          if (!res.data) {
            this.book_allotment = {
              projectId: this.projectId,
              userId: this.userId,
              role: this.role,
              books: [],
            };
          } else {
            this.book_allotment = res.data;
          }
        });
    } catch (error) {}
  }

  async loadOtherBookAllocation() {
    try {
      this.bookAllotmentService
        .getAllotedBookByProjectAndRole({
          projectId: this.projectId,
          role: this.role,
          userId: this.userId,
        })
        .subscribe((res) => {
          this.otherAllocationBooks = res.data
            .map((entry: any) => entry.books) // Extract the 'books' array from each entry
            .reduce(
              (accumulator: any, currentValue: any) =>
                accumulator.concat(currentValue),
              []
            );
        });
    } catch (error) {}
  }

  async stageField(event: any) {
    for (let i = 0; i < this.data.timeLineDetails.length; i++) {
      Object.values(this.data.timeLineDetails[i]).forEach((e) => {
        if (e.stageName == event) {
          this.stageValue = e.stageName;
          this.indexValue = i;
          return;
        }
      });
    }
    let ds: any = this.data.timeLineDetails[this.indexValue];
    this.dataSource = new MatTableDataSource<TimeLineDetails>(ds);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  closeModal() {
    try {
      if (this.isUpdated == true) {
        this.bookAllotmentService
          .allotBookToUser(this.book_allotment)
          .subscribe(
            (res: any) => {
              try {
                this.dialogRef.close({
                  projectId: this.data.projectId,
                  userId: this.data.userId,
                  role: this.data.role,
                  userArr: this.timeLineDetails,
                });
                this.dialog.open(PopUpModalComponent, {
                  data: { message: res.message },
                });
              } catch (error) {
                console.log(error);
              }
            },
            (error) => {
              this.dialogRef.close({
                projectId: this.data.projectId,
                userId: this.data.userId,
                role: this.data.role,
                userArr: this.timeLineDetails,
              });
              this.dialog.open(PopUpModalComponent, {
                data: { message: error.error.message },
              });
            }
          );
      } else {
        this.dialogRef.close({
          projectId: this.data.projectId,
          userId: this.data.userId,
          role: this.data.role,
          userArr: this.timeLineDetails,
        });
      }
    } catch (error) {
      console.log('Catch block  ', error);
    }
  }

  dataFromBookAllotment(data: BookAllotmentInterface) {
    try {
      this.isUpdated = true;
      let { isChecked, bookName, chapterName } = data;
      console.log(isChecked, bookName, chapterName);

      if (isChecked == true) {
        const existingBook = this.book_allotment.books.find(
          (item: any) => item.bookName === bookName
        );

        if (!existingBook) {
          // Book not present, add a new book with the chapter
          this.book_allotment.books.push({
            bookName,
            chapters: [chapterName],
            user_id: this.userId,
          });
        } else if (!existingBook.chapters.includes(chapterName)) {
          // Book is present, but chapter is not, add the chapter
          existingBook.chapters.push(chapterName);
        }
      } else {
        const index = this.book_allotment.books.findIndex(
          (item: any) => item.bookName === bookName
        );

        if (index !== -1) {
          // Book is present
          const book = this.book_allotment.books[index];

          const chapterIndex = book.chapters.findIndex(
            (e: any) => e === chapterName
          );

          if (chapterIndex !== -1) {
            // Chapter is present, remove it
            book.chapters.splice(chapterIndex, 1);

            if (book.chapters.length === 0) {
              // If chapters length is 0, remove the book
              this.book_allotment.books.splice(index, 1);
            }
          }
        }
      }

      console.log('Book Allotment ', this.book_allotment);
    } catch (error) {}
  }

  timeLineDetails: TimeLineDetails[] = this.data.timeLineDetails;
  // timeLineDetails: TimeLineDetails[] = [];

  // async addItem(value: TimeLineDetails) {
  //   if (this.timeLineDetails.length == 0) {
  //     this.timeLineDetails[0] = value;
  //   } else if (this.timeLineDetails.length) {
  //     let timeLine = this.timeLineDetails.findIndex(
  //       (timeLine: TimeLineDetails) => timeLine.bookName === value.bookName
  //     );
  //     if (timeLine > -1) {
  //       this.timeLineDetails[timeLine] = value;
  //     } else {
  //       this.timeLineDetails = [...this.timeLineDetails, value];
  //     }
  //   }
  //   console.log("timeLineDetails",this.timeLineDetails);

  // }

  async addItem(value: any) {
    for (let x = 0; x < this.timeLineDetails.length; x++) {
      Object.values(this.data.timeLineDetails[x]).forEach((e) => {
        if (e.bookName === value.bookName && e.stageName === value.stageName) {
          e.bookName = value.bookName;
          e.chapters = value.chapters;
          e.noOfDays = value.noOfDays;
          e.stageName = value.stageName;
        }
      });
    }
    // let timeLine = this.timeLineDetails.findIndex(
    //   (timeLine: TimeLineDetails) => timeLine.bookName === value.bookName
    // );
    // this.timeLineDetails[timeLine] = value;

    // if (this.timeLineDetails.length == 0) {
    //   this.timeLineDetails[0] = value;
    // } else if (this.timeLineDetails.length) {
    //   let timeLine = this.timeLineDetails.findIndex(
    //     (timeLine: TimeLineDetails) => timeLine.bookName === value.bookName
    //   );
    //   if (timeLine > -1) {
    //     this.timeLineDetails[timeLine] = value;
    //   } else {
    //     this.timeLineDetails = [...this.timeLineDetails, value];
    //   }
    // }
  }
}
