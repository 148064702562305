import {Injectable}          from '@angular/core';
import {Observable, Subject} from 'rxjs';

interface UserLoggedInData {
  loggedIn: boolean;
  role: string;
}

@Injectable()
export class AdminLoginService {
  isAdminLoggedIn = false;
  role!: string;
  loggedInData: UserLoggedInData | undefined;
  adminLogin = new Subject<any>();

  set isLoggedIn(val: UserLoggedInData) {
    console.log("Is Logged In Admin" , val)
    this.isAdminLoggedIn = val.loggedIn;
    this.role = val.role;
  }

  get adminIsLoggedIn(): any {
    this.loggedInData = {
      loggedIn: this.isAdminLoggedIn,
      role: this.role
    };
    return this.loggedInData;
  }

  listen(): Observable<any> {
    return this.adminLogin.asObservable();
  }

  pause(adminLoggedInData: any) {
    this.adminLogin.next(adminLoggedInData);
  }

  emitToSubscribers(): void {
    this.adminLogin.next(this.loggedInData);
  }
}
