<div class="container">

    <div class="main-section">
        <div class="main-container" #scrollContainer>
            <router-outlet (activate)="onActivate($event, scrollContainer)"></router-outlet>
        </div>
    </div>

</div>
<footer class="bg-primary">
    <div layout="row" layout-align="center center">
        <!-- 2022. Rhema for the Nations. All Rights Reserved -->
        <p layout-align="center center">Copyright &copy; {{currentYear}}. Marisree LLC. All Rights Reserved
        </p>
    </div>
</footer>