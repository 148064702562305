import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-translate-manager',
  templateUrl: './translation-manager.component.html',
  styleUrls: ['./translation-manager.component.scss'],
})
export class TranslateManagerComponent implements OnInit {
  @Input('translateGroup')
  public translateGroup: FormGroup | any;
  @Input() changing: Subject<boolean> | any;
  email: string | any;
  phNo: string | any;
  translateManagers: any | any;
  @Input() setProjectRole: any;
  @Input() projectId: any | '';
  timeLineInfo: any;

  constructor(private userDataService: UserDataService,
    private projectProfileService: ProjectProfileService,
    ) {}

  ngOnInit(): void {
    this.getUserByRole('Translation Manager');
  }

  getUserByRole(role: string) {
    this.userDataService.getUserByRole(role).subscribe((userData: any) => {
      this.translateManagers = this.alphaOrder(userData,'firstName');      
      this.email = '';
      this.phNo = '';
      if (this.setProjectRole) {
        const index = this.translateManagers.findIndex(
          (data: any) => data._id === this.setProjectRole._id
        );
        this.translateGroup.controls.translateManager.setValue(this.translateManagers[index]);
        this.setValue(this.translateManagers[index]);
        this.translateGroup.controls.translateManager.disable();
      }
    });
  }

  setValue(object: any) {
    this.timeLineInfo=object;
    this.email = object.email;
    this.phNo = object.mobileNumber;
  }

  alphaOrder(list: any,fieldName: any){    
     return list.sort((a: any,b: any)=>{
     if(a[fieldName]>b[fieldName]) return 1;
     else if (a[fieldName]<b[fieldName]) return -1;
     else return 0;
   });      
 }
 showUser() {  
  this.projectProfileService.showTimeline(this.timeLineInfo.id,this.projectId,this.timeLineInfo.role);
}
}
