<div class="flex items-center sm-w-100">
    <div [formGroup]="translateGroup" class="grid grid-cols-3  sm-grid-cols-1 grill sm-w-100">
        <mat-form-field color="accent" class="formfield">
            <mat-label>Translation Manager</mat-label>
            <mat-select (selectionChange)="setValue($event.value)" formControlName="translateManager" required>
                <mat-option *ngFor="let translateManager of translateManagers" [value]="translateManager">
                    {{translateManager.firstName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="translateGroup.controls.translateManager.errors?.required">Please select Translation
                Manager</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield mx-12">
            <input matInput type="text" placeholder="Translation Manager Email" [(ngModel)]="email"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield">
            <input matInput type="text" placeholder="Translation Manager Number" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="phNo" disabled>
        </mat-form-field>
    </div>
    <mat-icon (click)="showUser()"><span class="material-symbols-outlined"> av_timerss </span></mat-icon>
</div>