import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminLoginService } from 'src/app/services/login/adminlogin.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { default as decode } from 'jwt-decode';
import { DialogsService } from 'src/app/services/dialog/dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isShowPassword: string = 'password';
  hide: boolean = true;
  isLoading: boolean = false;
  email: any;
  password: any;
  isDisplay: boolean = false;
  message: any = '';
  adminLoggedInData: any;
  //adminLoggedIn = false;
  userLoggedIn = false;
  role: any;
  //tokenPayload: any
  isAdminActivate: any;
  constructor(
    private router: Router,
    private adminLogin: AdminLoginService,
    private userDataService: UserDataService,
    private adminLoginService: AdminLoginService,
    private dialogService: DialogsService
  ) {}

  ngOnInit(): void {
    this.isAdminActivate = localStorage.getItem('checkAdmin');
    if (this.isAdminActivate) {
      this.router.navigateByUrl('dashboard');
    }
  }

  parseJwt(token: any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  onSubmit() {
    // this.dialogService.confirm();    
    this.isLoading = true;
    if (!this.email && !this.password) {
      this.showNotification('All field required');
    } else if (!this.email) {
      this.showNotification('Email is required');
    } else if (!this.password) {
      this.showNotification('Password is required');
    } else {
      this.isLoading = true;
      let user = {
        email: this.email.trim(),
        password: this.password.trim(),
      };
      this.userDataService.adminLogin(user).subscribe(
        async (res) => {
          //this.tokenPayload = await decode(res.token.rhemaServer);
          //this.role = this.tokenPayload.role;
          //this.adminLoggedIn = true;
          let tokenPayload: any = await decode(res.token.rhemaServer);
          this.role = tokenPayload.role;
          this.userLoggedIn = this.role ? true : false;
          const userLoggedInData = {
            loggedIn: this.userLoggedIn,
            role: this.role,
          };
          this.adminLoginService.isLoggedIn = userLoggedInData;
          this.adminLoginService.pause({
            loggedIn: this.userLoggedIn,
            role: this.role,
          });
          const data = await res.token.rhemaServer;
          const isCheckAdmin = this.role === 'SuperAdmin' ? true : false;
          //localStorage.setItem('checkAdmin', this.adminLoggedIn.toString());
          localStorage.setItem('checkAdmin', isCheckAdmin.toString());
          localStorage.setItem('role', this.role);
          localStorage.setItem('token', data);
          localStorage.setItem('isUserLoggedIn', 'true');
          localStorage.setItem('email',user.email);

          // const checkAdmin = this.parseJwt(data);
          // sessionStorage.setItem('isAdmin', (checkAdmin.isAdmin) ? '1' : '0');

          // if (this.role !== 'Coordinator') {
          if (this.role !== 'Translation Manager') {
            this.router.navigateByUrl('dashboard');
          } else {
            this.router.navigateByUrl('performance');
          }
        },
        (error) => {
          console.log('Error', error);
          switch (error.status) {
            case 404:
              this.showNotification('Your email is not registered');
              break;
            case 403:
              this.showNotification('Your email is not verified');
              break;
            case 0:
              this.showNotification('Please check internet connection');
              break;
            default:
              this.showNotification('Please Enter Valid Details');
          }
        },
        () => {
          console.log('All good at Login Function');
        }
      );
    }

    console.log('Submit Function');
  }

  redirectToHome() {
    this.router.navigateByUrl('/');
  }
  showNotification(msg: any) {
    this.isDisplay = !this.isDisplay;
    this.isLoading = false;
    this.message = msg;
    setTimeout(() => {
      this.isDisplay = false;
      this.message = '';
    }, 1500);
  }
}
