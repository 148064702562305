<div class="flex items-center sm-w-100">
    <div [formGroup]="consultantGroup" class="grid grid-cols-3  sm-grid-cols-1 grill sm-w-100">
        <mat-form-field color="accent" class="formfield">
            <mat-label>Consultant</mat-label>
            <mat-select (selectionChange)="setValue($event.value)" formControlName="consultant" required>
                <mat-option *ngFor="let consultant of consultants" [value]="consultant">
                    {{consultant.firstName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="consultantGroup.controls.consultant.errors?.required">Please select consultant</mat-error>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield mx-12">
            <input matInput type="text" placeholder="Consultant Email" [(ngModel)]="email"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
        <mat-form-field color="accent" class="formfield">
            <input matInput type="text" placeholder="Consultant Number" [(ngModel)]="phNo"
                [ngModelOptions]="{standalone: true}" disabled>
        </mat-form-field>
    </div>
    <mat-icon (click)="showUser()"><span class="material-symbols-outlined"> av_timerss </span></mat-icon>
</div>