import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private http: HttpClient) {
  }

  createLanguage(languageObj: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/language/createLanguage`,
      languageObj
    );
  }

  getRegions(): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/regions`
    );
  }

  getCountriesByRegion(id: any): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/countriesByRegion/${id}`
    );
  }

  getLanguagesByCountry(id: any): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/languagesByCountry/${id}`
    );
  }

  getSourcLanguages(): Observable<any> {
    return this.http.get(
      `${environment.services.rhemaServer}/sourceLanguages`
    );
  }
}
