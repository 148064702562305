import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-consultant',
  templateUrl: './consultant.component.html',
  styleUrls: ['./consultant.component.scss']
})
export class ConsultantComponent implements OnInit {

  @Input('consultantGroup')
  public consultantGroup: FormGroup | any;
  consultants: any;
  email: string | any;
  phNo: string | any;
  @Input() setProjectRole: any;
  @Input() projectId: any | '';
  timeLineInfo: any;
 


  constructor(
    private userDataService: UserDataService,
    private projectProfileService: ProjectProfileService,
    ) {
  }

  ngOnInit(): void {
    this.getUserByRole('Consultant');
  }

  getUserByRole(role: string) {
    this.userDataService.getUserByRole(role).subscribe((userData: any) => {
      this.consultants = this.alphaOrder(userData,'firstName');      
      this.email = '';
      this.phNo = '';
      if (this.setProjectRole) {
        const index = this.consultants.findIndex((data: any) => data._id === this.setProjectRole._id);
        this.consultantGroup.controls.consultant.setValue(this.consultants[index]);
        this.setValue(this.consultants[index]);
        this.consultantGroup.controls.consultant.disable();
      }
    });
  }

  setValue(object: any) {
    this.timeLineInfo=object;
    this.email = object.email;
    this.phNo = object.mobileNumber;
  }

  alphaOrder(list: any,fieldName: any){ 
     return list.sort((a: any,b: any)=>{
     if(a[fieldName]>b[fieldName]) return 1;
     else if (a[fieldName]<b[fieldName]) return -1;
     else return 0;
   });      
 }

 showUser() {
  this.projectProfileService.showTimeline(this.timeLineInfo.id,this.projectId,this.timeLineInfo.role);
}

}
