<div class="subtable">
  <div class="bname" *ngIf="isDisplay == true">
    <input
      type="text"
      maxlength="3"
      matInput
      class="day"
      value="{{book?.noOfDays}}"
      name="{{book?.bookName}}"
      (change)="timeLineChange($event)"
    />
  </div>
  <div>
    <p class="flex items-center cursor-pointer" (click)="showChapters()">
      {{ book?.bookName }}
      <span>
        <mat-icon class="mat-icon-rtl-mirror">
          {{ isDisplay ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </span>
    </p>
  </div>

  <div>
    <div *ngIf="isDisplay">
      <form>
        <div class="noOfDays">
          <div class="days" *ngFor="let data of book?.chapters; let i = index">
            <label>{{ data.chapterName }}</label>
            <mat-form-field color="accent" class="mr-8 fields">
              <input
                matInput
                maxlength="3"
                name="{{ data.chapterName}}"
                value="{{ data.noOfDays }}"
                class="books"
                (change)="timeLineChange($event)"
              />
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

 
