import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';

@Component({
  selector: 'app-facilitator',
  templateUrl: './facilitator.component.html',
  styleUrls: ['./facilitator.component.scss']
})
export class FacilitatorComponent implements OnInit {

  @Input('facilitatorGroup')
  public facilitatorGroup: FormGroup | any;
  @Input() changing: Subject<boolean> | any;
  email: string | any;
  phNo: string | any;
  facilitators: any;
  @Input() setProjectRole: any;
  @Input() projectId: any | '';
  timeLineInfo: any;


  constructor(
    private projectProfileService: ProjectProfileService,

  ) { }

  ngOnInit(): void {
    this.changing.subscribe((response: any) => {
      this.facilitators = response;
      this.email = '';
      this.phNo = '';
      if (this.setProjectRole) {
        const index = this.facilitators.findIndex((data: any) => data._id === this.setProjectRole._id);
        this.facilitatorGroup.controls.facilitator.setValue(this.facilitators[index]);
        this.setValue(this.facilitators[index]);
        this.facilitatorGroup.controls.facilitator.disable();
      }
    });
  }

  setValue(object: any) {
    this.timeLineInfo=object;
    this.email = object.email;
    this.phNo = object.mobileNumber;
  }
  showUser() {    
    this.projectProfileService.showTimeline(this.timeLineInfo.id,this.projectId,this.timeLineInfo.role);
  }

}
