import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pop-up-modal',
  templateUrl: './pop-up-modal.component.html',
  styleUrls: ['./pop-up-modal.component.scss'],
})
export class PopUpModalComponent implements OnInit {
  popUpData: any;
  constructor(
    public dialogRef: MatDialogRef<PopUpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PopUpModalComponent
  ) {
    this.popUpData = data;    
  }

  ngOnInit(): void {}

  closeModel() {
    this.dialogRef.close();
  }
}
