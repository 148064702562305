<div class="modal-popup">
  <div mat-dialog-content>
    <span class="capitalize text-lg">{{ popUpData.message }}</span>
  </div> 
  <div class="example-button-container">
    <button
      mat-mini-fab
      color="warn"
      (click)="closeModel()"
      aria-label="Example icon button with a filter list icon"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
