import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-community',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.scss'],
})
export class CommunityComponent implements OnInit {
  @Input('communityGroup')
  public communityGroup: FormGroup | any;
  communities: any;
  email: string | any;
  phNo: string | any;
  @Input() projectId: any | '';
  @Input() setProjectRole: any;
  timeLineInfo: any;

  constructor(
    private userDataService: UserDataService,
    private projectProfileService: ProjectProfileService
  ) {}

  ngOnInit(): void {
    this.getUserByRole('Community');
  }

  getUserByRole(role: string) {
    this.userDataService.getUserByRole(role).subscribe(async (userData) => {
      this.communities = this.alphaOrder(userData, 'firstName');
      this.email = '';
      this.phNo = '';
      if (this.setProjectRole) {
        const index = this.communities.findIndex(
          (data: any) => data._id === this.setProjectRole._id
        );

        this.communityGroup.controls.community.setValue(
          this.communities[index]
        );

        console.log('this.communities[index] .. ', this.communities[index]);
        this.setValue(this.communities[index]);

        this.communityGroup.controls.community.disable();
      }
    });
  }

  setValue(object: any) {
    try {
      // this.timeLineInfo = object;
      this.email = object.email;
      this.phNo = object.mobileNumber;
    } catch (error) {
      console.log(error);
    }
  }

  alphaOrder(list: any, fieldName: any) {
    return list.sort((a: any, b: any) => {
      if (a[fieldName] > b[fieldName]) return 1;
      else if (a[fieldName] < b[fieldName]) return -1;
      else return 0;
    });
  }

  showUser() {
    this.projectProfileService.showTimeline(
      this.timeLineInfo.id,
      this.projectId,
      this.timeLineInfo.role
    );
  }
}
