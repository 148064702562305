import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  TimeLineDetails,
  Chapter,
} from 'src/app/page/project-edit/project-edit.component';

@Component({
  selector: 'app-sub-book-allotment',
  templateUrl: './sub-book-allotment.component.html',
  styleUrls: ['./sub-book-allotment.component.scss'],
})
export class SubBookAllotmentComponent implements OnInit {
  @Input() book: TimeLineDetails | any;
  @Input() stageName: string | undefined;
  @Input('noOfDays')
  @Input()
  projectId: string | undefined;
  @Input() userId: string | undefined;
  @Input() book_allotment: any;
  @Input() otherAllocationBooks: any;

  @Output()
  newItemEvent = new EventEmitter<any>();

  @Output() bookAllotmentEvent = new EventEmitter<any>();

  isDisplay: boolean = false;

  ngOnInit(): void {}

  constructor() {}

  showChapters() {
    this.isDisplay = !this.isDisplay;
  }

  // timeLineChange(event: any) {
  //   if (this.book?.bookName === event.target.name) {
  //     this.book!.bookName = event.target.name;
  //     this.book!.noOfDays = event.target.value;
  //   } else {
  //     const particularChapter: Chapter = this.book?.chapters.find(
  //       (chapter: Chapter) => chapter.chapterName === event.target.name
  //     ) as Chapter;
  //     particularChapter.noOfDays = event.target.value;
  //   }
  //   console.log("book event",this.book);
  //   this.newItemEvent.emit(this.book);
  // }

  timeLineChange(event: any) {
    if (this.book?.bookName === event.target.name) {
      this.book!.bookName = event.target.name;
      this.book!.noOfDays = event.target.value;
    } else {
      const particularChapter: Chapter = this.book?.chapters.find(
        (chapter: Chapter) => chapter.chapterName === event.target.name
      ) as Chapter;
      particularChapter.noOfDays = event.target.value;
    }
    this.newItemEvent.emit(this.book);
  }

  bookSelectionCheckbox(event: any, bookName: string, chapterName: string) {
    try {
      this.bookAllotmentEvent.emit({
        isChecked: event.checked,
        bookName,
        chapterName,
      });
    } catch (error) {}
  }

  isChapterSelected(
    bookName: string,
    chapterName: string,
    currentUserId: any
  ): boolean {
    try {
      const book = this.book_allotment.books.find(
        (item: any) => item.bookName === bookName
      );

      return book && book.chapters.includes(chapterName);
    } catch (error) {
      return false;
    }
  }

  // Function to check if any chapter of any book is allotted to someone else
  isChapterAllottedToOthers(
    bookName: string,
    chapterName: string,
    currentUserId: any
  ): boolean {
    if (this.otherAllocationBooks && this.otherAllocationBooks.length > 0) {
      const currentBookData = this.otherAllocationBooks.find(
        (item: any) => item.bookName === bookName
      );

      if (
        !currentBookData ||
        !currentBookData.chapters ||
        currentBookData.chapters.length === 0
      ) {
        return false;
      }

      return currentBookData.chapters.includes(chapterName);
    }

    return false;
  }
}

/**
 *
 *    book_allotement:{
 *      projectId:"",
 *      bookName:"",
 *      userId:"",
 *      chapters_alloted:[]
 *
 *    }
 * 
 * 




















 try {
      console.log('Chapter Name', chapterName);
      if (event.checked == true) {
        if (this.book_allotment.chapters_alloted.length > 0) {
          let isChapterAlreadyPresent =
            this.book_allotment.chapters_alloted.includes(chapterName);
          if (isChapterAlreadyPresent == false) {
            this.book_allotment.chapters_alloted = [
              ...this.book_allotment.chapters_alloted,
              chapterName,
            ];
          } else {
            console.log('Book Already Alloted');
          }
        } else {
          this.book_allotment.chapters_alloted.push(chapterName);
        }
      } else {
        let isChapterAlreadyPresent =
          this.book_allotment.chapters_alloted.includes(chapterName);
        if (isChapterAlreadyPresent == true) {
          this.book_allotment.chapters_alloted =
            this.book_allotment.chapters_alloted.filter(
              (e: any) => e !== chapterName
            );
        }
      }
    } catch (error) {}
 */
