import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  Chapter,
  TimeLineDetails,
} from 'src/app/page/project-edit/project-edit.component';
import { environment } from '../../../environments/environment';

export interface ProjectLanguageType {
  language: string;
  targetType: string;
}

@Injectable({
  providedIn: 'root',
})
export class BibleBooksService {
  previousState: string | undefined;
  youtube: string | undefined;
  projectLanguageTypeSubject: BehaviorSubject<ProjectLanguageType> =
    new BehaviorSubject({ language: 'english', targetType: 'source' });
  // bookJson: any;
  // timeLineDetails: TimeLineDetails[] | undefined;

  constructor(private http: HttpClient) {}

  getPreviousState() {
    return this.previousState;
  }

  setPreviousState(previousScreen: string) {
    this.previousState = previousScreen;
  }

  getBooks(): Observable<any> {
    return this.http.get(`${environment.services.rhemaServer}/bible/getBooks`);
  }

  

  getBooksByLanguage(language: string, type: string): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/bible/getBooksByLanguage/${language}/${type}`,
      {}
    );
  }

  getChaptersByBookName(body: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/bible/getChaptersByBookName`,
      body
    );
  }

  getBooksByPerformanceLanguage(data: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/dashboard/getBooksByPerformanceLanguage`,
      data
    );
  }

  getChaptersByPerformance(data: any): Observable<any> {    
    return this.http.post(
      `${environment.services.rhemaServer}/dashboard/getChaptersByPerformance`,
      data
    );
  }

  getPerformance(data: any): Observable<any>{
    return this.http.post(
      `${environment.services.rhemaServer}/dashboard/getPerformance`,
      data
    );
  }

  getFilesByChapter(body: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/bible/getFilesByChapter`,
      body
    );
  }

  getTranslatedVerse(project: any,languageName: any, type: any): Observable<any> {
    const data = {project:project, language: languageName, targetType: type };
    return this.http.post(
      `${environment.services.rhemaServer}/bible/getTranslatedVerses`,
      data
    );
  }

  setLanguageTypeInfo(project: any,languageName: any, type: any) {
    const tabInfo = { project:project, language: languageName, targetType: type };
    this.projectLanguageTypeSubject.next(tabInfo);
  }

  getCountriesByProject(data: any): Observable<any> {
    return this.http.post(
      `${environment.services.rhemaServer}/dashboard/getCountriesByProject`,
      data
    );
  }

  getSourceProjectList(sourceLanguage: any): Observable<any>{
    return this.http.post(
      `${environment.services.rhemaServer}/bible/getSourceProjectList`,
      {sourceLanguage}
    );
  }

  deleteSourceProjectList(data:any): Observable<any>{
    return this.http.post(
      `${environment.services.rhemaServer}/file/deleteSourceProjectList`,
      data
    );
  }


  deleteSourceProjectBookWise(data: any): Observable<any>{    
    return this.http.post(
      `${environment.services.rhemaServer}/file/deleteSourceProjectBookWise`,
      data
    );
  }

  deleteSourceProjectChapterWise(data: any): Observable<any>{    
    return this.http.post(
      `${environment.services.rhemaServer}/file/deleteSourceProjectChapterWise`,
      data
    );
  }
}
