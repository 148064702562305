<ng-container *ngIf="isDisplay">
  <mat-card class="font-primary projectCreate">
    <mat-card-header class="background-primary border-top-5">
      <mat-card-title class="font-primary">Edit Project</mat-card-title>
    </mat-card-header>

    <form [formGroup]="editProject">
      <div *ngIf="duplicateUsers && duplicateUsers.length" class="dup-user">
        <ul *ngFor="let user of duplicateUsers">
          <li>{{ user.role }} with {{ user.email }} added multiple times.</li>
        </ul>
      </div>

      <mat-card-content>
        <div class="grid grid-cols-4 sm-grid-cols-1 grill">
          <mat-form-field color="accent" class="mr-8">
            <input matInput placeholder="Project Name" formControlName="projectName" required />
            <mat-error *ngIf="editProject.controls.projectName.errors?.required">Please enter project name
            </mat-error>
          </mat-form-field>
          <mat-form-field color="accent" class="mr-8">
            <input matInput placeholder="Project Location" formControlName="projectLocation" required />
            <mat-error *ngIf="editProject.controls.projectLocation.errors?.required">Please enter project location
            </mat-error>
          </mat-form-field>
          <mat-form-field color="accent" class="matformfield">
            <mat-label>Select Region</mat-label>
            <mat-select formControlName="region" required ngDefaultControl (selectionChange)="getCountries($event)">
              <mat-option *ngFor="let region of regions" [value]="region">{{ region.regionName }}
              </mat-option>
            </mat-select>
            <mat-error> Please select a region </mat-error>
          </mat-form-field>
          <mat-form-field color="accent" class="matformfield1 mr-8">
            <mat-label>Select Country</mat-label>
            <mat-select formControlName="country" required ngDefaultControl (selectionChange)="getLanguages($event)">
              <mat-option *ngFor="let country of countriesList" [value]="country" class="mat-option-custom">
                {{ country.countryName }}
              </mat-option>
            </mat-select>
            <mat-error> Please select a country </mat-error>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-4 sm-grid-cols-1 grill">
          <mat-form-field color="accent" class="matformfield">
            <mat-label>Source Language 1</mat-label>
            <mat-select formControlName="majorLanguage1" required>
              <mat-option *ngFor="let language of sourceLanguages" [value]="language.language">
                {{ language.language }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="editProject.controls.majorLanguage1.errors?.required">Please select SourceLanguage 1
            </mat-error>
          </mat-form-field>
          <mat-form-field color="accent" class="matformfield">
            <mat-label>Source Language 2</mat-label>
            <mat-select formControlName="majorLanguage2" required>
              <mat-option *ngFor="let language of sourceLanguages" [value]="language.language">
                {{ language.language }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="editProject.controls.majorLanguage2.errors?.required">Please select SourceLanguage 2
            </mat-error>
          </mat-form-field>
          <mat-form-field color="accent" class="matformfield">
            <mat-label>Source Language 3</mat-label>
            <mat-select formControlName="majorLanguage3" required>
              <mat-option *ngFor="let language of sourceLanguages" [value]="language.language">
                {{ language.language }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="editProject.controls.majorLanguage3.errors?.required">Please select SourceLanguage 3
            </mat-error>
          </mat-form-field>

          <mat-form-field color="accent" class="matformfield">
            <mat-label>Target Language</mat-label>
            <mat-select formControlName="language" required>
              <mat-option *ngFor="let language of languages" [value]="language.languageName">
                {{ language.languageName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="editProject.controls.language.errors?.required">Please select consultant
            </mat-error>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-3 sm-grid-cols-1 grill">
          <mat-form-field color="accent" class="matformfield1 mr-8">
            <input matInput placeholder="Enter Partner" formControlName="partner" required />
            <mat-error *ngIf="editProject.controls.partner.errors?.required">Please enter partner name
            </mat-error>
          </mat-form-field>
          <mat-form-field class="matformfield1 mr-2">
            <input matInput [matDatepicker]="startdate" placeholder="Start-date" formControlName="startDate" required />
            <mat-datepicker-toggle matSuffix [for]="startdate"></mat-datepicker-toggle>
            <mat-datepicker #startdate></mat-datepicker>
            <mat-error *ngIf="editProject.controls.startDate.errors?.required">Please select date
            </mat-error>
          </mat-form-field>
          <mat-form-field class="matformfield1  sm-mr-2">
            <input matInput [matDatepicker]="endDate" placeholder="End-date" formControlName="endDate"
              (dateChange)="dateValidation()" required />
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
            <mat-error *ngIf="editProject.controls.endDate.errors?.required">Please select date
            </mat-error>
            <mat-hint style="color: red" *ngIf="dateError">Please select date greater than start date
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-4 sm-grid-cols-1 grill">
          <mat-form-field color="accent" class="matformfield">
            <mat-label>Project Type:</mat-label>
            <mat-select [value]="projectTypeValue" required [disabled]="true">
              <mat-option [value]="projectTypeValue">{{
                projectTypeValue
                }}</mat-option>
            </mat-select>
            <mat-error> Please select one option </mat-error>
          </mat-form-field>

          <div *ngIf="projectTypeValue == 'Video'">
            <mat-form-field color="accent" class="matformfield">
              <mat-label>Video Type:</mat-label>
              <mat-select [value]="projectTypeValue" required [disabled]="true">
                <mat-option [value]="projectTypeValue">{{
                  videoTypeValue
                  }}</mat-option>
              </mat-select>
              <mat-error> Please select one option </mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="projectTypeValue == 'Audio'">
            <mat-form-field color="accent" class="matformfield">
              <mat-label>Audio Type:</mat-label>
              <mat-select [value]="projectTypeValue" required [disabled]="true">
                <mat-option [value]="projectTypeValue">{{
                  videoTypeValue
                  }}</mat-option>
              </mat-select>
              <mat-error> Please select one option </mat-error>
            </mat-form-field>
          </div>

          <!-- <div *ngIf="checkProjectTypeValue !== null">
            <div *ngIf="checkProjectTypeValue == 'audio'">
              <mat-form-field color="accent" class="matformfield">
                <mat-label>Audio Type:</mat-label>
                <mat-select
                  formControlName="projectType"
                  (selectionChange)="checkAudioValueType($event)"
                >
                  <mat-option
                    *ngFor="let audio of audioOptionType"
                    [value]="audio"
                    >{{ audio }}</mat-option
                  >
                </mat-select>
                <mat-error> Please select one option </mat-error>
              </mat-form-field>
            </div>
            <div *ngIf="checkProjectTypeValue == 'video'">
              <mat-form-field color="accent" class="matformfield">
                <mat-label>Video Type:</mat-label>
                <mat-select
                  formControlName="projectType"
                  (selectionChange)="checkVideoType($event)"
                >
                  <mat-option *ngFor="let video of videos" [value]="video">{{
                    video
                  }}</mat-option>
                </mat-select>
                <mat-error> Please select one option </mat-error>
              </mat-form-field>
            </div>
          </div> -->
          <div>
            <mat-form-field color="accent" class="matformfield">
              <mat-label>Testament Type:</mat-label>
              <mat-select [disabled]="true" [value]="testamentTypeValue">
                <mat-option value="oldTestament" [value]="testamentTypeValue">{{
                  testamentTypeValue
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="flex items-center ">
            <mat-checkbox id="offlinefeature" formControlName="isSyncNeed">Offline
            </mat-checkbox>
            <mat-checkbox class="mx-12" formControlName="isBookAllocated">Book Allocation</mat-checkbox>
          </div>
        </div>

        <div class="flex pt-2 pb-4 flex-wrap checkbox-container justify-between" style="margin-right:14rem;">

          <div class="  mr-12 mb-2">
            <mat-checkbox formControlName="noCrossCheck" [disabled]="noCrossCheck === true">Skip Cross
              Check</mat-checkbox>
          </div>

          <div class=" checkBoxStyle mx-12 mb-2">
            <mat-checkbox formControlName="noCommunityCheck" [disabled]="noCommunityCheck === true">Skip Community
              Check</mat-checkbox>
          </div>

          <div class="checkBoxStyle mx-12 mb-2">
            <mat-checkbox formControlName="noCitCheck" [disabled]="noCitCheck === true">Skip CIT Check</mat-checkbox>
          </div>

          <div class="checkBoxStyle mx-12 mb-2">
            <mat-checkbox formControlName="noConsultantCheck" [disabled]="noConsultantCheck === true">Skip Consultant
              Check</mat-checkbox>
          </div>

        </div>

        <div class="comp">
          <div class="">
            <!--Mtt-->
            <div formArrayName="mtts">
              <div *ngFor="
                  let mtt of editProject['controls']['mtts']['controls'];
                  let i = index
                ">
                <div class="flex" [formGroupName]="i" style="position: relative">
                  <app-mt-translator [setProjectRole]="projRoleMTT[i]"
                    [mttGroup]="editProject.controls.mtts.controls[i]" [changing]="changingMttValue"
                    [projectId]="projectId" class="sm-w-100"></app-mt-translator>
                  <div class="flex items-center justify-center flex-direction icons">
                    <div [ngClass]="{
                        disableDeleteicon:
                          editProject.controls.mtts.controls.length === 1,
                        deleteicon:
                          editProject.controls.mtts.controls.length > 1
                      }">
                      <span (click)="deleteMtt(i)">
                        <mat-icon>delete</mat-icon>
                      </span>
                    </div>
                    <div class="addfacilitator">
                      <span (click)="addMtt()">
                        <mat-icon>add</mat-icon>
                      </span>
                    </div>
                    <!-- <div>
                      <mat-icon (click)="showTimeline('Mtt')"
                        ><span class="material-symbols-outlined">
                          av_timerss
                        </span></mat-icon
                      >
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!--Facilitator-->
            <div formArrayName="facilitators">
              <div *ngFor="
                  let facilitator of editProject['controls']['facilitators']['controls'];
                  let i = index
                ">
                <div class="flex" [formGroupName]="i" style="position: relative">
                  <app-facilitator [setProjectRole]="projRolefacilitator[i]"
                    [facilitatorGroup]="editProject.controls.facilitators.controls[i]"
                    [changing]="changingFacilitatorValue" [projectId]="projectId" class="sm-w-100">

                  </app-facilitator>
                  <div class="flex items-center justify-center flex-direction icons">
                    <div [ngClass]="{
                        disableDeleteicon:
                          editProject.controls.facilitators.controls.length === 1,
                        deleteicon:
                          editProject.controls.facilitators.controls.length > 1
                      }">
                      <span (click)="deleteFacilitator(i)">
                        <mat-icon>delete</mat-icon>
                      </span>
                    </div>
                    <div class="addfacilitator">
                      <span (click)="addFacilitator()">
                        <mat-icon>add</mat-icon>
                      </span>
                    </div>
                    <!-- <div>
                      <mat-icon (click)="showTimeline('Facilitator')"
                        ><span class="material-symbols-outlined">
                          av_timerss
                        </span></mat-icon
                      >
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- CIT -->
          <div formArrayName="cits">
            <div *ngFor="
                let cit of editProject['controls']['cits']['controls'];
                let i = index
              ">
              <div class="flex" [formGroupName]="i" style="position: relative">
                <app-cit [setProjectRole]="projRoleCit[i]" [citGroup]="editProject.controls.cits.controls[i]"
                  [projectId]="projectId" class="sm-w-100"></app-cit>
                <div class="flex items-center justify-center flex-direction icons">
                  <div [ngClass]="{
                      disableDeleteicon:
                        editProject.controls.cits.controls.length === 1,
                      deleteicon: editProject.controls.cits.controls.length > 1
                    }">
                    <span (click)="deleteCit(i)">
                      <mat-icon>delete</mat-icon>
                    </span>
                  </div>
                  <div class="addfacilitator">
                    <span (click)="addCit()">
                      <mat-icon>add</mat-icon>
                    </span>
                  </div>
                  <!-- <div>
                    <mat-icon (click)="showTimeline('Cit')"
                      ><span class="material-symbols-outlined">
                        av_timerss
                      </span></mat-icon
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!--consultant-->
          <div formArrayName="consultants">
            <div *ngFor="
                let consultant of editProject['controls']['consultants'][
                  'controls'
                ];
                let i = index
              ">
              <div class="flex" [formGroupName]="i" style="position: relative">
                <app-consultant [setProjectRole]="projRoleConsultant[i]" [consultantGroup]="
                    editProject.controls.consultants.controls[i]
                  " [projectId]="projectId" class="sm-w-100"></app-consultant>
                <div class="flex items-center justify-center flex-direction icons">
                  <div [ngClass]="{
                      disableDeleteicon:
                        editProject.controls.consultants.controls.length === 1,
                      deleteicon:
                        editProject.controls.consultants.controls.length > 1
                    }">
                    <span (click)="deleteConsultant(i)">
                      <mat-icon>delete</mat-icon>
                    </span>
                  </div>
                  <div class="addfacilitator">
                    <span (click)="addConsultant()">
                      <mat-icon>add</mat-icon>
                    </span>
                  </div>
                  <!-- <div>
                    <mat-icon (click)="showTimeline('Consultant')"
                      ><span class="material-symbols-outlined">
                        av_timerss
                      </span></mat-icon
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <!--Technician-->
          <div formArrayName="technicians">
            <div *ngFor="
                let technician of editProject['controls']['technicians'][
                  'controls'
                ];
                let i = index
              ">
              <div class="flex" [formGroupName]="i" style="position: relative">
                <app-technician [setProjectRole]="projRoleTechnician[i]" [technicianGroup]="
                    editProject.controls.technicians.controls[i]
                  " class="sm-w-100" [projectId]="projectId"></app-technician>
                <div class="flex items-center justify-center flex-direction icons">
                  <div [ngClass]="{
                      disableDeleteicon:
                        editProject.controls.technicians.controls.length === 1,
                      deleteicon:
                        editProject.controls.technicians.controls.length > 1
                    }">
                    <span (click)="deleteTechnician(i)">
                      <mat-icon>delete</mat-icon>
                    </span>
                  </div>
                  <div class="addfacilitator">
                    <span (click)="addTechnician()">
                      <mat-icon>add</mat-icon>
                    </span>
                  </div>
                  <!-- <div>
                    <mat-icon (click)="showTimeline('Technician')"
                      ><span class="material-symbols-outlined">
                        av_timerss
                      </span></mat-icon
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <!--Coordinator-->
          <div formArrayName="coordinators">
            <div *ngFor="
                let coordinator of editProject['controls']['coordinators']['controls'];
                let i = index
              ">
              <div class="flex" [formGroupName]="i" style="position: relative">
                <app-coordinator [setProjectRole]="projRoleCoordinator[i]"
                  [coOrdinatorGroup]="editProject.controls.coordinators.controls[i]" class="sm-w-100"
                  [changing]="changingCoordinatorValue" [projectId]="projectId">
                </app-coordinator>
                <div class="flex items-center justify-center flex-direction icons">
                  <div [ngClass]="{
                      disableDeleteicon:
                        editProject.controls.coordinators.controls.length ===
                        1,
                      deleteicon:
                        editProject.controls.coordinators.controls.length > 1
                    }">
                    <span (click)="deleteCoOrdinator(i)">
                      <mat-icon>delete</mat-icon>
                    </span>
                  </div>
                  <div class="addCoOrdinator">
                    <span (click)="addCoOrdinator()">
                      <mat-icon>add</mat-icon>
                    </span>
                  </div>
                  <!-- <div>
                    <mat-icon (click)="showTimeline('Coordinator')"
                      ><span class="material-symbols-outlined">
                        av_timerss
                      </span></mat-icon
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <!-- Translation Manager -->
          <div formArrayName="translateManagers">
            <div *ngFor="
                let translateManager of editProject['controls']['translateManagers']['controls'];
                let i = index
              ">
              <div class="flex" [formGroupName]="i" style="position: relative">
                <app-translate-manager [setProjectRole]="projRoleTranslate[i]"
                  [translateGroup]="editProject.controls.translateManagers.controls[i]" class="sm-w-100"
                  [changing]="changingTranslateValue" [projectId]="projectId"></app-translate-manager>
                <div class="flex items-center justify-center flex-direction icons">
                  <div [ngClass]="{
                      disableDeleteicon:
                        editProject.controls.translateManagers.controls.length === 1,
                      deleteicon:
                        editProject.controls.translateManagers.controls.length > 1
                    }">
                    <span (click)="deletetranslateManager(i)">
                      <mat-icon>delete</mat-icon>
                    </span>
                  </div>
                  <div class="addfacilitator">
                    <span (click)="addtranslateManager()">
                      <mat-icon>add</mat-icon>
                    </span>
                  </div>
                  <!-- <div>
                    <mat-icon (click)="showTimeline('Translate Manager')"
                      ><span class="material-symbols-outlined">
                        av_timerss
                      </span></mat-icon
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <div formArrayName="communities">
            <div *ngFor="
                let community of editProject['controls']['communities']['controls'];
                let i = index
              ">
              <div class="flex sm-w-100" [formGroupName]="i" style="position: relative">
                <app-community [communityGroup]="editProject.controls.communities.controls[i]"
                  [setProjectRole]="projRoleCommunity[i]" [changing]="changingTranslateValue" [projectId]="projectId"
                  class="sm-w-100"></app-community>

                <div class="flex items-center justify-center flex-direction icons">
                  <div [ngClass]="{
                      disableDeleteicon:
                        editProject.controls.communities.controls.length === 1,
                      deleteicon: editProject.controls.communities.controls.length > 1
                    }">
                    <span (click)="deleteCommunity(i)">
                      <mat-icon>delete</mat-icon>
                    </span>
                  </div>
                  <div class="addfacilitator">
                    <span (click)="addCommunity()">
                      <mat-icon>add</mat-icon>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div formArrayName="countryCoordinators">
            <div *ngFor="
                let community of editProject['controls']['countryCoordinators']['controls'];
                let i = index
              ">
              <div class="flex sm-w-100" [formGroupName]="i" style="position: relative">
                <app-country-coordinator [countryCordinatorsGroup]="editProject.controls.countryCoordinators.controls[i]"
                  [setProjectRole]="projRoleCountryCoordinator[i]" [changing]="changingTranslateValue" [projectId]="projectId" [countryName]="editProject.get('country').value.countryName"
                  class="sm-w-100"></app-country-coordinator>

                <div class="flex items-center justify-center flex-direction icons">
                  <div [ngClass]="{
                      disableDeleteicon:
                        editProject.controls.communities.controls.length === 1,
                      deleteicon: editProject.controls.communities.controls.length > 1
                    }">
                    <span (click)="deleteCountryCoordinator(i)">
                      <mat-icon>delete</mat-icon>
                    </span>
                  </div>
                  <div class="addfacilitator">
                    <span (click)="addCountryCoordinator()">
                      <mat-icon>add</mat-icon>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

        </div>

        <div class="button-align">
          <button mat-raised-button [ngClass]="{
              'btn-primary rounded-sm text-white': editProject.valid
            }" (click)="updateProject(); editProject['submitted'] = false" [disabled]="!editProject.valid">
            Update Project
          </button>
        </div>
      </mat-card-content>
    </form>
  </mat-card>
</ng-container>