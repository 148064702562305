import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialExampleModule } from './material.module';

import { MatCardModule } from '@angular/material/card';
import { LoginComponent } from './page/login/login.component';
import { ProjectListComponent } from './page/project-list/project-list.component';
import { HomeComponent } from './page/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationComponent } from './components/notification/notification.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { HttpClientModule } from '@angular/common/http';
import { UserDataService } from './services/user-data/user-data.service';
import { AdminLoginService } from './services/login/adminlogin.service';
import { CreateLanguageComponent } from './page/create-language/create-language.component';
import { EditUserDetailComponent } from './page/edit-user-detail/edit-user-detail.component';
import { CreateUserComponent } from './page/create-user/create-user.component';
import { ProjectEditComponent } from './page/project-edit/project-edit.component';
import { UploadAudioFilesComponent } from './page/upload-audio-files/upload-audio-files.component';
import { UploadVideoFilesComponent } from './page/upload-video-files/upload-video-files.component';
import { SideNavbarComponent } from './components/side-navbar/side-navbar.component';
import { SpinnerModalComponent } from './components/spinner-modal/spinner-modal.component';
import { DialogsService } from './services/dialog/dialog.service';
import { BooksComponent } from './page/books/books.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { SeriesPipe } from './series.pipe';
import { PopUpModalComponent } from './components/pop-up-modal/pop-up-modal.component';
import { UserDetailComponent } from './page/user-detail/user-detail.component';
import { ConfirmationAlertComponent } from './components/confirmation-alert/confirmation-alert.component';
import { TestmentBooksComponent } from './page/testment-books/testment-books.component';
import { DeleteTestmentBooksComponent } from './page/delete-testment-books/delete-testment-books.component';
import { ResetPasswordComponent } from './page/reset-password/reset-password.component';
import { CreateProjectComponent } from './page/create-project/create-project.component';
import { CitComponent } from './page/create-project/cit/cit.component';
import { ConsultantComponent } from './page/create-project/consultant/consultant.component';
import { FacilitatorComponent } from './page/create-project/facilitator/facilitator.component';
import { MtTranslatorComponent } from './page/create-project/mt-translator/mt-translator.component';
import { TechnicianComponent } from './page/create-project/technician/technician.component';
import { TranslateManagerComponent } from './page/create-project/tranlsation-manager/translation-manager.component';
import { AuthGuardService } from './services/AuthGuard/auth-guard.service';
import { UploadChatbotCitFilesComponent } from './page/upload-chatbot-cit-files/upload-chatbot-cit-files.component';
import { UploadChatbotMttFilesComponent } from './page/upload-chatbot-mtt-files/upload-chatbot-mtt-files.component';
import { CoordinatorComponent } from './page/create-project/coordinator/coordinator.component';
import { PerformanceComponent } from './page/performance/performance.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { SubTableComponent } from './components/timeline/sub-table/sub-table.component';
import { TimelineExtensionComponent } from './page/timeline-extension/timeline-extension.component';
import { SupportComponent } from './page/support/support.component';
import { SupportAttachViewComponent } from './page/support-attach-view/support-attach-view.component';
import { ProjectDashboardComponent } from './components/project-dashboard/project-dashboard.component';
import { UploadSignFilesComponent } from './page/upload-sign-files/upload-sign-files.component';
import { BookAllotmentComponent } from './components/book-allotment/book-allotment.component';
import { SubBookAllotmentComponent } from './components/book-allotment/sub-book-allotment/sub-book-allotment.component';
import { CommunityComponent } from './page/create-project/community/community.component';
import { CountryCoordinatorComponent } from './page/create-project/country-coordinator/country-coordinator.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { UserGraphComponent } from './page/user-graph/user-graph.component';
@NgModule({
  declarations: [
    AppComponent,
    SideNavbarComponent,
    HomeComponent,
    SeriesPipe,
    LoginComponent,
    ProjectListComponent,
    NotificationComponent,
    SpinnerComponent,
    CreateLanguageComponent,
    EditUserDetailComponent,
    CreateUserComponent,
    ProjectEditComponent,
    UploadAudioFilesComponent,
    UploadSignFilesComponent,
    UploadVideoFilesComponent,
    SpinnerModalComponent,
    BooksComponent,
    PieChartComponent,
    PopUpModalComponent,
    UserDetailComponent,
    ConfirmationAlertComponent,
    TestmentBooksComponent,
    DeleteTestmentBooksComponent,
    ResetPasswordComponent,
    CreateProjectComponent,
    CitComponent,
    ConsultantComponent,
    FacilitatorComponent,
    MtTranslatorComponent,
    TechnicianComponent,
    CoordinatorComponent,
    TranslateManagerComponent,
    CommunityComponent,
    CountryCoordinatorComponent,
    UploadChatbotCitFilesComponent,
    UploadChatbotMttFilesComponent,
    PerformanceComponent,
    TimelineExtensionComponent,
    TimelineComponent,
    SubTableComponent,
    SupportComponent,
    SupportAttachViewComponent,
    ProjectDashboardComponent,
    BookAllotmentComponent,
    SubBookAllotmentComponent,
    DashboardComponent,
    UserGraphComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialExampleModule,
    MatCardModule,
    HttpClientModule,
    NgApexchartsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    UserDataService,
    AdminLoginService,
    DialogsService,
    AuthGuardService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
