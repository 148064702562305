import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-cit',
  templateUrl: './cit.component.html',
  styleUrls: ['./cit.component.scss'],
})
export class CitComponent implements OnInit {
  @Input('citGroup')
  public citGroup: FormGroup | any;
  cits: any;
  email: string | any;
  phNo: string | any;
  @Input() projectId: any | '';
  @Input() setProjectRole: any;
  timeLineInfo: any;

  constructor(
    private userDataService: UserDataService,
    private projectProfileService: ProjectProfileService,
    ) {}

  ngOnInit(): void {
    this.getUserByRole('CIT');
  }

  getUserByRole(role: string) {
    this.userDataService.getUserByRole(role).subscribe(async (userData) => {
      this.cits =this.alphaOrder(userData, 'firstName');
      this.email = '';
      this.phNo = '';
      if (this.setProjectRole) {
        const index = this.cits.findIndex(
          (data: any) => data._id === this.setProjectRole._id
        );
        this.citGroup.controls.cit.setValue(this.cits[index]);

        this.setValue(this.cits[index]);

        this.citGroup.controls.cit.disable();
      }
    });
  }

  setValue(object: any) {
    this.timeLineInfo=object;
    this.email = object.email;
    this.phNo = object.mobileNumber;
  }

  alphaOrder(list: any,fieldName: any){    
    return list.sort((a: any,b: any)=>{
    if(a[fieldName]>b[fieldName]) return 1;
    else if (a[fieldName]<b[fieldName]) return -1;
    else return 0;
  });      
}

showUser() {
  this.projectProfileService.showTimeline(this.timeLineInfo.id,this.projectId,this.timeLineInfo.role);
}

}
