import { Injectable }                 from '@angular/core'; 
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'; 
import { SpinnerModalComponent } from 'src/app/components/spinner-modal/spinner-modal.component';

@Injectable()
export class DialogsService {
  public spinnerDialog: any;

  constructor(public dialog: MatDialog) {
  }

  public confirm() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = false;
    this.spinnerDialog = this.dialog.open(SpinnerModalComponent, dialogConfig);
  }

  public closeModal() {
    this.spinnerDialog.close();
    return this.spinnerDialog.afterClosed();
  }
}