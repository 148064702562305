import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdminLoginService } from '../login/adminlogin.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  isAdminActivate: string | any;
  isAdminActivateCheck: boolean | any;
  role: string | any;
  checkAdmin: boolean | any

  constructor(private auth: AdminLoginService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | any {
    //   this.isAdminActivate = localStorage.getItem('checkAdmin');
    //   this.role = localStorage.getItem('role');
    //   this.isAdminActivateCheck = ((this.isAdminActivate === 'true') && (this.role === 'SuperAdmin')) ? true : false;

    //   if (!this.isAdminActivate) {
    //     this.router.navigate(['/login']);
    //     return
    //   }
    //   else if (!this.isAdminActivateCheck) {
    //     this.router.navigate(['/login']);
    //     return
    //   }

    //   return this.isAdminActivateCheck;

    
    this.role = localStorage.getItem('role');
    const isAllow = (this.role === 'SuperAdmin' || this.role === 'Translation Manager') ? true : false;

    if (!isAllow) {
      localStorage.clear();
      this.router.navigate(['/']);
      return
    }
    if(this.role != 'Translation Manager' && state.url=='/performance'){
      this.router.navigate(['/dashboard']);
    }

    return true
  }
}
