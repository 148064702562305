<div class="timeline mt-8">
  <mat-card class="dash-board">
    <div class="dashboard-header text-white border-top-5">
      <mat-card-header class="bg-primary flex justify-between items-center">
        <mat-card-title>
          <span
            class="text-primary font-primary text-white capitalize username"
            >{{ data.role }}</span
          >
        </mat-card-title>

        <button
          mat-mini-fab
          color="warn"
          (click)="closeModal()"
          aria-label="Example icon button with a filter list icon"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-header>
      <mat-form-field class="mobile-view">
        <mat-label>Stage Name</mat-label>
        <mat-select
          (ngModelChange)="stageField($event)"
          [(ngModel)]="stage[0].stageName[0]"
        >
          <mat-option *ngFor="let data of stage[0].stageName" [value]="data">{{
            data
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="example">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="bookName">
            <th mat-header-cell *matHeaderCellDef class="header-format">
              Book Name
            </th>
            <td mat-cell *matCellDef="let element">
              <app-sub-book-allotment
                [book]="element"
                [projectId]="projectId"
                [stageName]="stageValue"
                [userId]="userId"
                [book_allotment]="book_allotment"
                [otherAllocationBooks]="otherAllocationBooks"
                (newItemEvent)="addItem($event)"
                (bookAllotmentEvent)="dataFromBookAllotment($event)"
                style="width: 100%"
              >
              </app-sub-book-allotment>
            </td>
          </ng-container>
          >
          <ng-container matColumnDef="noOfDays">
            <th mat-header-cell *matHeaderCellDef class="header-format">
              Allotment
            </th>
            <td mat-cell *matCellDef="let element"></td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 30, 40, 50]"
        [pageSize]="20"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </mat-card>
</div>
