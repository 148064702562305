import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  isAdminActivate: string | any;
  isAdminActivateCheck: boolean | any;
  isUserLoggedInCheck: boolean | any;
  role: string | any;
  checkAdmin: boolean | any;

  constructor(private router: Router) {
    this.checkLogin();
  }

  ngOnInit(): void {
    this.checkLogin();
  }

  createUser() {
    this.router.navigateByUrl('createUser');
  }

  uploadAudioFiles() {
    this.router.navigateByUrl('uploadAudioFiles');
  }

  adminLogin() {
    this.router.navigateByUrl('login');
  }

  playStore() {
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.rhema.bt';
  }
  onWindowsDownload(){
    window.location.href='https://drive.google.com/file/d/1tNihvL4B22htqmpcU6UqgeiaxJpjeSC8/view'
  }

  onMacDownload(){
    window.location.href='https://drive.google.com/file/d/1hr5F1A7_6XCjSU_ofbDRasGW-TK7pnHm/view'
  }
  redirectToHome() {
    this.router.navigateByUrl('/');
  }

  checkLogin() {
    console.log('Home Page , check login');
    this.isAdminActivate = localStorage.getItem('checkAdmin');

    this.role = localStorage.getItem('role');
    this.isUserLoggedInCheck = localStorage.getItem('isUserLoggedIn');
    this.isAdminActivateCheck =
      this.isAdminActivate === 'true' && this.role === 'SuperAdmin'
        ? true
        : false;

    if (this.isAdminActivate === 'true') {
      this.router.navigate(['/dashboard']);
    } 
    
    // else if (
    //   this.isUserLoggedInCheck == 'true' &&
    //   this.role == 'Translation Manager'
    // ) {
    //   console.log('ROle Treans', this.role, this.isUserLoggedInCheck);
    //   this.router.navigate(['/performance']);
    // }
  }
}
