import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ProjectProfileService } from 'src/app/services/project-profile/project-profile.service';
import { UserDataService } from 'src/app/services/user-data/user-data.service';

@Component({
  selector: 'app-coordinator',
  templateUrl: './coordinator.component.html',
  styleUrls: ['./coordinator.component.scss'],
})
export class CoordinatorComponent implements OnInit {
  @Input('coOrdinatorGroup')
  public coOrdinatorGroup: FormGroup | any;
  coordinators: any;
  email: string | any;
  phNo: string | any;
  @Input() setProjectRole: any;
  @Input() projectId: any | '';
  timeLineInfo: any;


  constructor(private userDataService: UserDataService,
    private projectProfileService: ProjectProfileService,
    ) {} 

  ngOnInit(): void {
    this.getUserByRole('Coordinator');
  }

  getUserByRole(role: string) {
    this.userDataService
      .getUserByRole(role)
      .subscribe((coordinatorsData: any) => {
        this.coordinators = this.alphaOrder(coordinatorsData,'firstName');
        this.email = '';
        this.phNo = '';
        if (this.setProjectRole) {
          const index = this.coordinators.findIndex(
            (data: any) => data._id === this.setProjectRole._id
          );
          this.coOrdinatorGroup.controls.coordinator.setValue(
            this.coordinators[index]
          ); 
          this.setValue(this.coordinators[index]);
          this.coOrdinatorGroup.controls.coordinator.disable();
        }
      });
  }


  setValue(object: any) {
    this.timeLineInfo=object;
    this.email = object.email;
    this.phNo = object.mobileNumber;
  }

  
  alphaOrder(list: any,fieldName: any){
     return list.sort((a: any,b: any)=>{
     if(a[fieldName]>b[fieldName]) return 1;
     else if (a[fieldName]<b[fieldName]) return -1;
     else return 0;
   });      
 }

 showUser() {
  this.projectProfileService.showTimeline(this.timeLineInfo.id,this.projectId,this.timeLineInfo.role);
}

}
