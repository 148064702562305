<div class="subtable">
  <div class="bname" *ngIf="isDisplay == true"></div>
  <div>
    <p class="flex items-center cursor-pointer" (click)="showChapters()">
      {{ book?.bookName }}
      <span>
        <mat-icon class="mat-icon-rtl-mirror">
          {{ isDisplay ? "expand_more" : "chevron_right" }}
        </mat-icon>
      </span>
    </p>
  </div>

  <div>
    <div *ngIf="isDisplay">
      <form>
        <div class="noOfDays">
          <div
            class="days flex items-center justify-between"
            style="width: 132%;padding: 0.5rem 0;"
            *ngFor="let data of book?.chapters; let i = index"
          >
            <label>{{ data.chapterName }}</label>
            <span> </span>
            <div class="checkbox-input-css">
              <span class="mx-2" style="margin: 0 0.5rem">
                <mat-checkbox
                  [checked]="
                    isChapterSelected(book?.bookName, data.chapterName, userId)
                  "
                  [disabled]="
                    isChapterAllottedToOthers(
                      book.bookName,
                      data.chapterName,
                      userId
                    )
                  "
                  (change)="
                    bookSelectionCheckbox(
                      $event,
                      book.bookName,
                      data.chapterName
                    )
                  "
                ></mat-checkbox
              ></span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
